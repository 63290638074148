<template>
    <Publication :streamBlocks="streamContained" :tags="combinedTags" :class="pageClass" :programs="affiliationContent">
        <template #hero>
            <AttributionBanner v-if="renderAttributionBanner" v-bind="attributions.attributionItems[0]" />
            <ImageHero v-if="renderHero" v-bind="hero" :noCaption="false" :pageLanguage="pageLanguage" />
        </template>

        <template #head>
            <PublicationSummary
                :subtype="subtype === 'other' && !eyebrow ? 'post' : subtype"
                :subtypeLabel="subtype === 'other' ? eyebrow : undefined"
                :attributions="attributions"
                :dek="dek"
                :title="title"
                :contributors="contributors"
                :translations="translations"
                :pdfResources="pdfResources"
                :publisherName="publisherName"
                :publishedAt="publishedAt"
                :pageLanguage="pageLanguage"
            />
        </template>

        <template #main-left>
            <AsideBlock :hasPadding="false">
                <AffiliationCard
                    v-for="item in affiliationContent"
                    :key="item.reference.path"
                    :heading="item.reference.title"
                    :dek="item.reference.dek || item.reference.about"
                    headingElement="h3"
                    :image="item.image"
                    :mobileImage="item.mobileImage"
                    :logoImage="item.logoImage"
                    :url="item.reference.path"
                    :pageLanguage="pageLanguage"
                    :eyebrow="$t(item.eyebrow)"
                    class="card"
                    :isDCPrimary="isDCPrimary"
                />
            </AsideBlock>
        </template>

        <template #main-right>
            <template v-if="contributors?.length">
                <AsideBlock v-if="contributors?.length > 1" :heading="$t('authors', pageLanguage)">
                    <ul class="authors">
                        <li v-for="person in contributors" :key="person.id" class="author">
                            <PersonListItem :person="person" :isInline="true" size="33" />
                        </li>
                    </ul>
                </AsideBlock>
                <PersonListItem
                    v-else-if="
                        contributors?.length === 1 &&
                        (contributors?.[0].dek?.root.children[0].children.length > 0 ||
                            contributors?.[0].personTitle !== null)
                    "
                    :person="contributors?.[0]"
                    size="33"
                />
            </template>
        </template>

        <template v-if="renderRelatedContent" #footer>
            <RelatedContentTier
                v-if="relatedContent?.length"
                :attributionTitle="relatedAttributionLink"
                :content="relatedContent"
                :pageLanguage="pageLanguage"
                :pieceTitle="title"
            >
            </RelatedContentTier>
        </template>
    </Publication>
</template>

<script setup>
const { getCenterTagsBySyndication, currentCenter } = useCenters();
const { locales } = useI18n();
const { pageLanguage } = usePageDataStore();

const props = defineProps({
    contributors: {
        type: Array,
    },
    publishedAt: {
        type: String,
    },
    dek: {
        type: Object,
    },
    hero: {
        type: Object,
    },
    id: {
        type: String,
    },
    eyebrow: {
        type: String,
    },
    parent: {
        type: Object,
    },
    syndications: {
        type: Object,
    },
    publisherName: {
        type: String,
        default: '',
    },
    path: {
        type: String,
    },
    relatedContent: {
        type: Array,
        default: () => [],
    },
    slug: {
        type: String,
    },
    streamContained: {
        type: Array,
    },
    subtype: {
        type: String,
    },
    tags: {
        type: Array,
    },
    title: {
        type: String,
    },
    pdfResources: {
        type: Array,
    },
    translations: {
        type: Object,
        default: () => {},
    },
    seo: {
        type: Object,
    },
    locale: {
        type: String,
        default: '',
    },
});
let jsonImg = '';
if (props.hero?.image?.url) {
    jsonImg = props.hero.image.url;
}
let canonicalURL = 'https://carnegieendowment.org' + props.path;
if (props.locale) {
    canonicalURL = canonicalURL + '?lang=' + props.locale;
}

let jsonAuthor = '';
if (props.contributors?.length) {
    for (const [index, person] of props.contributors.entries()) {
        jsonAuthor = jsonAuthor + '{"@type": "Person","name": "' + person.title + '"}';
        if (index < props.contributors.length - 1) {
            jsonAuthor = jsonAuthor + ',';
        }
    }
}

const jsonld = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: props.title,
    image: jsonImg,
    url: canonicalURL,
    author: jsonAuthor,
};
useHead({
    script: [
        {
            hid: 'breadcrumbs-json-ld',
            type: 'application/ld+json',
            innerHTML: JSON.stringify(jsonld),
        },
    ],
    link: [
        {
            rel: 'canonical',
            href: canonicalURL,
        },
    ],
});

const attributions = useAttribution(props.syndications, props.parent);

let isDCPrimary = true;
if (attributions.attributionItems.length && attributions?.attributionIcon !== "emissary") {
    isDCPrimary = false;
}

const relatedAttributionLink = computed(() => {
    if (props.syndications?.blogs[0]?.reference?.title.length) {return props.syndications.blogs[0].reference.title}
    else if (currentCenter.value.title === "Carnegie Endowment for International Peace") {return 'Carnegie'}
    else {return currentCenter.value.title}
})

const pageClass = computed(() => {
    return pageLanguage === 'ar' ? 'direction-rtl' : '';
});

const affiliationContent = computed(() => {
    return getAffiliationContent(props.syndications);
});
const collectionContent = getCollectionContent(props.syndications);

const renderAttributionBanner = computed(() => {
    return attributions?.attributionType === 'blog';
});

const renderHero = computed(() => {
    return props.hero?.image && props.subtype !== 'legacyInTheMedia';
});

const renderRelatedContent = computed(() => {
    return props.subtype !== 'legacyInTheMedia';
});

const combinedTags = getCenterTagsBySyndication(props.syndications, props.tags);

let GTMType = props.subtype;
if (props.syndications?.blogs.length) {
    GTMType = props.subtype + ' - ' + props.syndications.blogs[0].reference.title;
}
const { GTMCenter, GTMAuthors, GTMPrograms, GTMProjects, GTMRegions, GTMTopics } = useGTMContent(
    'post',
    props,
    affiliationContent,
    combinedTags,
    collectionContent,
);
const GTMLang = props.locale;

const headerAttributesStore = useHeaderAttributesStore();

onMounted(() => {
    window.dataLayer.push({
        Center: GTMCenter,
        Doctype: GTMType,
        Expert: GTMAuthors,
        'Taxonomy Tags Program': GTMPrograms,
        'Taxonomy Special Project': GTMProjects,
        'Taxonomy Tags Regions': GTMRegions,
        'Taxonomy Tags Topics': GTMTopics,
        'Page Language': GTMLang,
        event: 'new_data_layer',
    });
    headerAttributesStore.updateHeaderStyleAttributes({
        background: 'white',
        condensedOverride: renderAttributionBanner,
    });
});
</script>

<style lang="scss" scoped>
.affiliation-banner {
    padding: vertical-space(1);
    text-align: center;
}

.article-hero {
    &__image {
        height: 60rem;
        object-fit: cover;
        width: 100%;
    }
}
</style>
